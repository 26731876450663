<template>
  <div class="preloader-wrapper">
    <div class="preloader">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>
<script>
export default {
  created() {
    window.top.postMessage("3DS-authentication-complete", "*");
  },
};
</script>
